export function formatDate(time,needDayNum=true,includeTime=false) {
    const newTime = +time * 1000
    let oldDate = new Date(newTime)
    let newDate = new Date()
    var dayNum = "";
    var getTime = (newDate.getTime() - oldDate.getTime()) / 1000;

    if (getTime < 60 * 5) {
        dayNum = "刚刚";
    } else if (getTime >= 60 * 5 && getTime < 60 * 60) {
        dayNum = parseInt(getTime / 60) + "分钟前";
    } else if (getTime >= 3600 && getTime < 3600 * 24) {
        dayNum = parseInt(getTime / 3600) + "小时前";
    } else if (getTime >= 3600 * 24 && getTime < 3600 * 24 * 30) {
        dayNum = parseInt(getTime / 3600 / 24) + "天前";
    } else if (getTime >= 3600 * 24 * 30 && getTime < 3600 * 24 * 30 * 12) {
        dayNum = parseInt(getTime / 3600 / 24 / 30) + "个月前";
    } else if (time >= 3600 * 24 * 30 * 12) {
        dayNum = parseInt(getTime / 3600 / 24 / 30 / 12) + "年前";
    }

    let year = oldDate.getFullYear();
    let month = (oldDate.getMonth() + 1).toString().padStart(2,0);
    let day = (oldDate.getDate()).toString().padStart(2,0);
    let hour = (oldDate.getHours().toString().padStart(2,0));
    let minute = (oldDate.getMinutes()).toString().padStart(2,0);
    let second = (oldDate.getSeconds()).toString().padStart(2,0);
    const dateStr = year + "-" + month + "-" + day
    let fullStr = includeTime ? dateStr + " " + hour + ":" + minute + ":" + second : dateStr ;
    const str = needDayNum ? dayNum + " " + fullStr : fullStr
    return str
}