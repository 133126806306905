import { removeToken,setToken } from '@/utils/auth' // get token from cookie
import { getUserInfo,login } from "@/api/user"

const getDefaultState = () => {
    return {
        userInfo: null,
        hasLogin: false
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_USER:(state,payload) => {
        state.userInfo = payload
    },
    SET_LOGIN:(state,payload) => {
        state.hasLogin = payload
    }
}
const actions = {
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            removeToken()
            commit('RESET_STATE')
            resolve()
        })
    },
    //login
    login({ commit }, userInfo) {
        return new Promise((resolve,reject)=>{
          login(userInfo).then(res=>{
            const { data } = res
            if(data) {
              commit('SET_USER',data)
              commit('SET_LOGIN',true)
              setToken(data.token)
              resolve()
            }else {
              reject(res)
            }
          }).catch(error=>{
            reject(error)
          })
        })
    },
    // get user info 恢复登录状态
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(res=>{
                if (res.data) {
                    commit('SET_USER', res.data)
                    commit('SET_LOGIN',true)
                    resolve()
                } else {
                    // 未登录 或token已过期
                    reject(res.msg)
                }
            })
        })
    },
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove token
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}