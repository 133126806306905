import request from '@/utils/request'

// 登录
export function login(data) {
    return request({
        url: '/api/user/login/login',
        method: 'post',
        data
    })
}

// 注册
export function register(data) {
    return request({
        url: '/api/user/login/register',
        method: 'post',
        data
    })
}
//获取验证码
export function getCode(data){
    return request({
        url:'/api/user/login/sendSms',
        method: 'post',
        data
    })
}
//获取用户信息
export function getUserInfo(data){
    return request({
        url:'/api/user/personal/userInfo',
        method: 'post',
        data
    })
}
// 修改用户信息
export function editUserInfo(data){
    return request({
        url:'/api/user/personal/editInfo',
        method: 'post',
        data
    })
}
// 修改手机号
export function editUserPhone({phone,code}){
    return request({
        url:'/api/user/personal/editPhone',
        method:'post',
        data:{
            phone,
            code
        }
    })
}
// 找回密码
export function editPassword(data){
    return request({
        url:'/api/user/login/resetPassword',
        method: 'post',
        data
    })
}
// 获取兴趣
export function getUserInterestInfo() {
    return request({
        url:'/api/user/interest/getInterest',
        method: 'get'
    })
}
// 修改兴趣货源
export function editInterestProduct(data){
    return request({
        url:'/api/user/interest/setGoods',
        method: 'post',
        data
    })
}
// 修改兴趣资讯
export function editInterestNews(data){
    return request({
        url:'/api/user/interest/setArticle',
        method: 'post',
        data
    })
}
// 资讯浏览记录
export function getNewsRecord(){
    return request({
        url:'/api/article/viewLog',
        method: 'get'
    })
}
// 选品浏览记录
export function getProductRecord(){
    return request({
        url:'/api/goods/viewLog',
        method: 'get'
    })
}
// 认证信息
export function getAuthInfo(params){
    return request({
        url:'/api/user/personal/getAuthApply',
        method:'get',
        params
    })
}
// 认证申请
export function submitAuthApply(data){
    return request({
        url:'/api/user/personal/authApply',
        method: 'post',
        data
    })
}

// 货源列表
export function getMyGoodsList(params){
    return request({
        url:'/api/user/goods/getList',
        method: 'get',
        params
    })
}

// 添加 编辑货源
export function addEditMyGoods(data){
    return request({
        url:'/api/user/goods/addEdit',
        method: 'post',
        data
    })
}
// 货源详情
export function myGoodsDetail(data){
    return request({
        url:'/api/user/goods/getDetail',
        method:'post',
        data
    })
}
// 货源上下架
export function changeMyGoodsStatus(data){
    return request({
        url:'/api/user/goods/isSell',
        method:'post',
        data
    })
}
// 货源删除
export function delMyGoods(data){
    return request({
        url:'/api/user/goods/delete',
        method:'post',
        data
    })
}